import { forwardRef } from "react";
import { Checkbox as CheckboxDaisy } from "react-daisyui";

import { ICheckboxProps } from "./type";

import { cn } from "~/utilities/cn";

export type Ref = HTMLInputElement;

const Checkbox = forwardRef<Ref, ICheckboxProps>(
  (
    { labelClass, checkboxClass, icon, checkboxContainerClass, ...restProps },
    ref
  ) => {
    return (
      <div className={cn("flex items-center gap-3", checkboxContainerClass)}>
        <CheckboxDaisy
          ref={ref}
          {...restProps}
          className={cn(
            "checkbox checkbox-sm", // Use ! to increase specificity
            checkboxClass
          )}
        />
        <label htmlFor={restProps.id} className="flex items-center gap-1">
          {icon && <div className="w-8">{icon}</div>}
          {restProps.label?.startsWith("<p>") && (
            <div
              dangerouslySetInnerHTML={{ __html: restProps.label }}
              className={cn("ql-editor")}
            />
          )}
          {!restProps.label?.startsWith("<p>") && (
            <span className={cn("text-sub-title-14", labelClass)}>
              {restProps.label}
            </span>
          )}
        </label>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
